.comparison-container {
    padding: 20px;
    /* text-align: center; */
  }
  
  .comparison-container h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .comparison-table {
    display: flex;
    justify-content: space-around;
    background-color: #e9ecef; /* Light blue-gray background */
    padding: 20px;
  }
  
  .column {
    background-color: #f8f9fa; /* White background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 45%;
    

  }
  
  .column h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .column ul {
    list-style-type: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* width: max-content; */
    margin-left: 34%;
    
    padding: 0;
  }
  
  .column li {
    margin: 5px 0;
    font-size: 1rem;
    color: #555;
  }
  
 
  