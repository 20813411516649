/* Wrapper for the entire component */
.partner-wrapper {
  font-family: 'Poppins', sans-serif;
  color: white;   
  /* display: grid; */

}

/* Introductory content styles */
.intro-content {
  margin-bottom: 30px;
  text-align: center;
}

.intro-content h2 {
  font-size: 22px;
  color: #000000;
  margin-bottom: 10px;
  text-align: center;
  
}

.intro-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  text-align: start;
  margin:0 20px;
}

/* Partner container for image + form */
.partner-container {
  display: grid;
   height: 28rem;  
  align-items: center;
  justify-content: start;
  background-color: #3C6798;
  /* padding: 20px; */
  grid-template-columns: repeat(12, 1fr);
}

.partner-image{
  grid-column: span 6;

}
/* Left Section: Image */
.partner-image img {
  height: 448px;
  width:874px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Right Section: Form styles */
.partner-form-section {
  grid-column: span 6;
  margin-right: 1rem;
  /* flex: 1; */
}

.partner-form-section h3 {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 10rem;
}

.partner-form {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between form groups */
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

label {
  font-size: 16px;
  font-weight: bold;
  flex-basis: 40%; /* Label takes up 40% of row */
  text-align: right;
}

input {
  flex-basis: 60%;
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 14px;
}

input:focus {
  outline: 2px solid #4ca1af;
}

.submitt-text {
  /* font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #4ca1af;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
  align-self: center; */
  width: fit-content;
  margin-left: 40rem;
}

/* .submit-text:hover {
  background-color: #357d88;
} */

/* Responsive Design */
@media (max-width: 768px) {
  .partner-container {
    flex-direction: column;
    align-items: center;
  }

  .partner-image img {
    width: 100%;
  }
}
