/* Footer Container */
.footer {
  background-color: #8BA5C1;
  padding: 30px 0;
  font-family: 'Poppins', sans-serif; 
}

/* Central Container for Layout */
.footer .container {
  max-width: 960px;
  margin: 0 auto;
}

/* Row for Side-by-Side Layout */
.row {
  display: flex;
  justify-content: space-between; /* Links on the left, Contact Info on the right */
  align-items: flex-start; /* Align items at the top */
  margin-right: 10px;
  gap: 10rem;
}

/* Links Section */
.links-container {
  flex: 1; /* Takes only the space required */
  text-align: left;
  text-align: center;
  /* Align content to the left */
}
.containerr {
  max-width: 960px;
  margin: 0 auto;
  margin-right: 10px;
}
.parent-container {
  display: flex; /* Or grid */
  gap: 20px; /* Adds 20px space between child elements */
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the left */
}

.contact-info h4 {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left; /* Ensures header is aligned properly */
  font-weight: 600; /* Bold weight for headers */
}

.contact-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.contact-info li {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  margin-bottom: 15px;
  gap: 10px; /* Add spacing between the icon and text */
}

.contact-info .icon {
  font-size: 20px; /* Adjust icon size */
  color: black; /* Icon color */
}

.contact-info a, .contact-info p {
  color: white; /* Text color */
  text-decoration: none;
  font-size: 16px;
  font-weight: 400; /* Normal weight for body text */
  line-height: 1.5; /* Proper line spacing for readability */
}

.contact-info p {
  margin: 0;
  word-break: break-word; /* Handles long text gracefully */
}
/* Contact Info Section */
.contact-info {
  flex: 1; /* Takes only the space required */
  text-align: right; /* Align content to the right */
}

/* Section Headings */
.footer h4 {
  margin-bottom: 20px;
  color: #FFF9F9;
}

/* Lists Styling */
.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #FFF9F9;
  text-decoration: none;
}

.footer p {
  margin-bottom: 10px;
  color: #FFF9F9;
}

/* Copyright Section */
.copyright {
  text-align: center;
  margin-top: 30px;
  color: #FFF9F9;
}
