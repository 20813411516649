.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    padding: 20px;
  }
  
  .promo h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  /* .promo span {
    color: #ffc107;
  } */
  
  /* .promo button {
    background: #ffc107;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  } */
  
  .product img {
    height: 200px;
    width: 95 vw;
  }
  
  