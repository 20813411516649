.support-form-container {
    display: flex;
    flex-direction: column; /* Change to column for vertical alignment */
    gap: 20px;
  }
  .support-container{
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #070708;
    margin-top: -10px;
  }
  

  .support-form{
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;

  }
  .form-header{
    font-weight: 600;
    font-size: 28px;
    line-height:30px;
    color: #070708;
  }
  .form-input{
    flex-basis: 60%;
    padding: 8px;
    border: 1px solid #F6F1F1;
    font-size: 20px;
  }
   .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
  }
  
  .form-label {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins',sans-serif;
    margin-right: 5px;
     /* Change to left alignment */
  }
  
  
  
  .form-input {
    flex-basis: 60%;
    padding: 8px;
    border: 1px solid #F6F1F1;
    font-size: 14px;
    background-color:#F3F0F0;
  }
  
  .summary-textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    border: 1px solid #F3F0F0;
    border-radius: 5px;
    resize: none;
    text-align: right;
    background-color: #F3F0F0;
    margin-right:20px;
  }
  .submit-textt {
    width: fit-content;
    margin-left: 40rem;
    color: #004AAD;
    font-weight: 800;
  }

  
  