/* Apply Poppins font globally */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  color: #333;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
