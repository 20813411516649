/* Global font settings */
body {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font globally */
  }
  
  /* Header Top */
  .header-top {
    background-color: #4E536D;
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif; /* Ensures Poppins is applied */
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    /* justify-content: space-between; */
    /* background-color: #0052cc; */
    padding: 10px 20px;
    align-items: center;
    color: white;
    font-family: 'Poppins', sans-serif; /* Ensures Poppins is applied */
  }
  .nav-bar-section{
    width: 100%;
    background-color: #0049B2;
  }
  .navbar ul {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: end;

  }

  
  .navbar ul li {
    font-size: 16px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  /* Logo */
  .logo img {
    height: 50px;
  }
  