/* Importing Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.title-section {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title-section h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.title-section h1 span {
  color: #000000;
}

.content-section {
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/src/assets/background-image.jpg');
  flex: 1;
  flex-direction: row;
}

.image-section {
  flex: 1;
 /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60%;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-family: 'Poppins',sans-serif;
}

h2 {
  font-size: 24px;
  margin-right: 10px;
  margin-bottom: 20px;
}

h2 span {
  font-weight: 600;
  color: #000000;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.8rem;
}

label {
  font-weight: 400%;
  flex-basis: 40%;
  font-size: 16px;
  text-align: right;

}

input {
    flex-basis: 60%;
    padding: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
}
input:focus {
    outline: 2px;
}
.submit-texxt {
    font-weight: 800;
}
button {
  padding: 10px 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: black;
}
@media (max-width: 768px) {
    .partner-container {
      flex-direction: column;
      align-items: center;
    }
  
    .partner-image img {
      width: 100%;
    }
  }
  
