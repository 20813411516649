.about-container {
  padding: 20px;
  background: linear-gradient(to bottom, #FBF0F0, #e8dada); /* Light pink gradient */
  /* border-top: 4px solid #fcb900;  */
  color: #333; /* Dark gray text */
  font-family: 'Poppins', sans-serif; /* Ensures Poppins is used */
  text-align: justify; /* Justifies the text */
}

.about-container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.about-container p {
  font-size: 1rem;
  line-height: 1.8;
  margin: 0 20px;
  /* max-width: 1000px;  */
  font-weight: 400;
  text-align: justify; /* Ensures the paragraph text is justified */
  text-justify: inter-word; /* Distributes words for justified alignment */
}
